#left-eye {
    animation: scale-left 1s cubic-bezier(0.680, -0.550, 0.265, 1.550) 3s infinite alternate-reverse;
    transform-origin: right center;
    transform-box: fill-box;
}

@keyframes scale-left {
    0% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1.5, 1.5);
    }
}


#right-eye {
    animation: scale-right 1s cubic-bezier(0.680, -0.550, 0.265, 1.550) 6s infinite alternate-reverse;
    transform-origin: left center;
    transform-box: fill-box;
}

@keyframes scale-right {
    0% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(2, 2);
    }
}

.preloader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
}

.preloader {
    width: 50px;
    height: 50px;
    border: 5px solid #0080cc;
    border-radius: 50%;
    border-color: #0080cc transparent #0080cc transparent;
    -webkit-animation: spin 2s linear infinite;
    animation: preload 2s linear infinite;
    margin: 40px auto 40px auto;
}


/* Safari */
@-webkit-keyframes preload {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes preload {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}